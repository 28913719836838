<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.hub_id" v-validate="{ required: true }"  id="hub_id" name="hub_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a hub</option>
                <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
              </select>
              <div class="invalid-feedback">Hub is required</div>
            </div>
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()">Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-hover table-bordered" id="hub_stock_consumption_history">
            <thead>
            <tr>
              <th>#</th>
              <th>PR Number</th>
              <th>Product Name</th>
              <th>Product Unit</th>
              <th>Free Product</th>
              <th>Amount/Quantity</th>
              <th>Entry Date</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(hub_consumption, index) in hub_consumptions.data" :key="index">
              <td>{{ hub_consumptions.from + index }}</td>
              <td>{{ hub_consumption.free_purchase_request_item.purchase_request_item.purchase_request.pr_number }}</td>
              <td>{{ hub_consumption.free_purchase_request_item.purchase_request_item.product.name }}</td>
              <td>{{ hub_consumption.free_purchase_request_item.purchase_request_item.product.unit }}</td>
              <td>{{ hub_consumption.free_purchase_request_item.name }}</td>
              <td>{{ hub_consumption.amount }}</td>
              <td>{{ hub_consumption.entry_date }}</td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="hub_consumptions.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="hub_consumptions" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import { Empty } from 'ant-design-vue'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      hubs: [],
      hub_consumptions: {},
      search_data: {
        hub_id: '',
        date_range: '',
      },
      btnLoading: false,
      loading: false,
      flag: false,
      show: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.codes()
  },
  methods: {
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    codes() {
      apiClient.get('api/consumption/codes')
        .then(response => {
          this.hubs = response.data.hubs
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.flag = true
          this.loading = true
          this.btnLoading = true
          apiClient.post('api/admin/hub/free/product-stock/consumption-history', this.search_data)
            .then(response => {
              this.btnLoading = false
              this.loading = false
              this.flag = true
              this.hub_consumptions = response.data.hub_consumptions
            })
            .catch(error => {
              this.btnLoading = false
              console.log(error)
            })
        }
      })
    },
    getResults(page = 1) {
      this.$validator.validate().then(valid => {
        if (valid) {
          apiClient.post('api/admin/hub/free/product-stock/consumption-history?page=' + page, this.search_data)
            .then(response => {
              this.hub_consumptions = response.data.hub_consumptions
            })
        }
      })
    },
  },
}
</script>
